import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'profile-icon',
  templateUrl: './profile-icon.component.html',
  styleUrl: './profile-icon.component.css'
})
export class ProfileIconComponent implements OnInit, OnChanges {

  @Input() name: string = '';
  @Input() iconSize: string = '24px';
  letters: string = '';
  
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['name'] || changes['iconSize']) this.ngOnInit();
  }

  ngOnInit(): void {
    if(this.name) {
      let words = this.name.split(' ');
      console.log(words)
      if(words?.length > 1) {
        this.letters = words[0][0] + words[1][0];
        this.letters = this.letters.toUpperCase();
      } else {
        this.letters = words[0].substring(0,2).toUpperCase();
      }
    }
    if(this.name === '') this.letters = '';
  }

}

import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Label } from 'src/app/models/label';
import { VoiceService } from 'src/app/services/voice.service';

@Component({
  selector: 'assign-label',
  templateUrl: './assign-label.component.html',
  styleUrl: './assign-label.component.css'
})
export class AssignLabelComponent implements OnInit, OnChanges {

  @Input() assignedLabel: any[] = [];
  @Input() inbox!: number;
  @Input() autofocus: boolean = false;
  @Output() close = new EventEmitter<Label[]>();
  labelsList: Label[] = [];
  loaderLabelList: boolean = false;
  loaderLabelPagination: boolean = false;
  loaderAssignLabel: boolean = false;
  pageNumber: number = 1;
  pageNext: boolean = false;
  labelSet = new Set<number>();
  showLabelsDropdown: boolean = false;

  @ViewChild('labelsDiv') labelsDiv!: ElementRef;
  divHeight: number = 40;

  constructor(
    private _voiceService: VoiceService,
  ) {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['autofocus']) {
      console.log(this.autofocus);
      if(this.autofocus) this.showLabelsDropdown = true;
    }
  }

  ngOnInit(): void {
    if(this.autofocus) this.showLabelsDropdown = true;
    this.labelSet.clear();
    this.loaderLabelList = true;
    let response = this._voiceService.getLabel(this.inbox,1);
    response.subscribe((resp)=>{
      if(resp.body.code === 200) {
        try {
          if(resp.body.next) this.pageNext = true;
          else this.pageNext = false;
          for (var index = 0; index < resp.body.data?.length; index++) {
            this.labelsList.push(new Label(resp.body.data[index]));
          }
          this.loaderLabelList = false;
          this.loaderLabelPagination = false;
          this.pageNumber++;
        } catch (ex) {
        }
      }
    },(error)=>{
      console.log(error);
      this.loaderLabelList = false;
      this.loaderLabelPagination = false;
    });
    this.assignedLabel?.forEach((label)=>{
      this.labelSet.add(label?.id);
      this.labelSet.add(label?.label_id);
    })
  }

  checkDivHeight() {
    setTimeout(() => {
      this.divHeight = this.labelsDiv.nativeElement.offsetHeight;
    },100)
  }
  
  getLabels(pageNumber:number){
    this.loaderLabelPagination = true;
    let response = this._voiceService.getLabel(this.inbox,pageNumber);
    response.subscribe((resp)=>{
      try {
        if(resp.body.code === 200) {
          if(resp.body.next) this.pageNext = true;
          else this.pageNext = false;
          if(pageNumber === 1) this.labelsList = [];
          for (var index = 0; index < resp.body.data?.length; index++) {
            this.labelsList.push(new Label(resp.body.data[index]));
          }
          this.loaderLabelList = false;
          this.loaderLabelPagination = false;
          this.pageNumber++;
        }
      } catch (exception) {
      }
    },(error)=>{
      console.log(error);
      this.loaderLabelList = false;
      this.loaderLabelPagination = false;
    });
  }

  onLabelsScroll(event: any) {
    if (
      event.target.scrollHeight -
        event.target.offsetHeight -
        event.target.scrollTop <
      50
    ) {
      if (this.pageNext && !this.loaderLabelPagination) {
        this.getLabels(this.pageNumber);
      }
    }
  }

  closeDropdown() {
    this.showLabelsDropdown = false;
    let labels: Label[] = [];
    this.labelsList?.forEach((label) => {
      if(this.labelSet.has(label?.id)) {
        labels.push(label);
      }
    })
    this.close.emit(labels);
  }

}

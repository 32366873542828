import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Customer } from '../models/customer';
import { CloudNumber } from '../models/cloud-number';

@Injectable({
  providedIn: 'root'
})
export class VoiceService {

  makeCall = new BehaviorSubject<any>([]);
  viewedCloudNumber!: CloudNumber;

  constructor(private http: HttpClient) { }

  callCustomer(customer: Customer) {
    this.makeCall.next(customer);
  }

  placeCall(obj: any): Observable<any> {
    return this.http.post<any>('/channel/voice/phone-call',obj, {
      observe: 'response'
    })
  }

  getCallLogs(page:number, params: Params): Observable<any> {
    return this.http.get<any>('/voice/call/log?page='+page, {
      observe: 'response',
      params: params,
    })
  }

  getCallConversations(page: number, inbox: number): Observable<any> {
    return this.http.get<any>('/conversations/call/'+inbox+'?page='+page, {
      observe: 'response',
    })
  }

  getSingleCallLog(params: Params): Observable<any> {
    return this.http.get<any>('/voice/call/log', {
      observe: 'response',
      params: params,
    })
  }

  searchCallLogs(page: number,number: string): Observable<any> {
    return this.http.get<any>('/search/call?q='+number+'&page='+page, {
      observe: 'response',
    })
  }

  getCallRecording(id: number): Observable<any> {
    return this.http.get<any>('/voice/call/recording/'+id, { observe: 'response'});
  }

  getInboxes(): Observable<any> {
    return this.http.get<any>('/voice/inbox', {
      observe: 'response'
    })
  }

  updateInbox(obj: any): Observable<any> {
    return this.http.put<any>('/voice/inbox', obj, {
      observe: 'response'
    })
  }

  deleteInbox(id: number): Observable<any> {
    return this.http.delete<any>('/voice/inbox/'+id, {
      observe: 'response'
    })
  }

  killCall(obj: any): Observable<any> {
    return this.http.put<any>('/voice/call/outbound',obj, {
      observe: 'response'
    })
  }

  getNewCloudNumber(page: number,state: string = 'All'): Observable<any> {
    if(state === 'All') {
      return this.http.get<any>('/channel/voice/cloud-number', {
        observe: 'response',
        params: {
          page: page,
        }
      })
    } else {
      return this.http.get<any>('/channel/voice/cloud-number', {
        observe: 'response',
        params: {
          page: page,
          q: state,
        }
      })
    }
  }
  
  buyCloudNumber(obj: any): Observable<any> {
    return this.http.post<any>('/voice/number/buy',obj, {
      observe: 'response'
    })
  }

  getCloudNumberAssignees(id: number,page: number= 1, status: string = 'all', search: string = ''): Observable<any> {
    let params :Record<string,any> = {}
    if(status !== 'all') params['status'] = status;
    if(search !== '') params['q'] = search;
    return this.http.get<any>('/channel/voice/cloud-number/assign/'+id, {
      observe: 'response',
      params: params,
    })
  }

  assignToCloudNumber(obj: any): Observable<any> {
    return this.http.post<any>('/channel/voice/cloud-number/assign',obj, {
      observe: 'response'
    })
  }

  deleteCloudNumber(obj: any): Observable<any> {
    return this.http.patch<any>('/channel/voice/inbox', obj, {
      observe: 'response'
    })
  }

  assignLabelToCall(obj: any): Observable<any> {
    return this.http.put<any>('/labels/call', obj, {
      observe: 'response'
    })
  }

  holdCall(obj: any): Observable<any> {
    return this.http.put<any>('/channel/voice/phone-call/hold', obj, {
      observe: 'response'
    })
  }

  muteCall(obj: any): Observable<any> {
    return this.http.put<any>('/channel/voice/phone-call/mute', obj, {
      observe: 'response'
    })
  }

  addCallLogNote(id: number,obj: any): Observable<any> {
    return this.http.post<any>('/note/'+id,obj, {
      observe: 'response'
    })
  }

  transferCall(inbox: number, obj: any): Observable<any> {
    return this.http.post<any>('/channel/voice/call/transfer/'+inbox,obj, {
      observe: 'response'
    })
  }

  getTunes(): Observable<any> {
    return this.http.get<any>('/channel/voice/tune', {
      observe: 'response'
    })
  }

  rateCallLog(obj: any): Observable<any> {
    return this.http.post<any>('/voice/call/rating',obj, {
      observe: 'response'
    }) 
  }
  importNumber(data:any): Observable <any>{
    return this.http.post<any>('/voice/number/import',data)
  }

  searchAreaCode(data:string):Observable <any>{
    return this.http.get('/voice/number/inventory?area_code='+data)
  }

  addLabel(inbox: number,obj: any): Observable<any> {
    return this.http.post<any>('/labels/'+inbox, obj, { observe: 'response' });
  }

  updateLabel(inbox: number,obj: any, id: number): Observable<any> {
    return this.http.put<any>('/labels/' + inbox + '/'+ id + '/', obj, {
      observe: 'response',
    });
  }

  getLabel(inbox: number,pageNumber: number): Observable<any> {
    return this.http.get<any>('/labels/'+ inbox + '?page=' + pageNumber, {
      observe: 'response',
    });
  }

  deleteLabel(inbox: number,id: number): Observable<any> {
    return this.http.delete<any>('/labels/' + inbox + '/'+ id + '/', {
      observe: 'response',
    });
  }

  assignLabel(inbox: number, obj: any) :Observable<any> {
    return this.http.put<any>('/labels/'+inbox+'/call', obj, {
      observe: 'response',
    })
  }

  getAssignLabel(inbox: number) :Observable<any> {
    return this.http.get<any>('/labels/'+inbox+'/call', {
      observe: 'response',
    })
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

declare var stripe: any;

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(
    private http: HttpClient,
  ) { }


  getPaymentGatewayKeys(): Observable<any> {
    return this.http.get<any>('/business/p-access', { observe: 'response'});
  }

  addPaymentGateway(obj: any): Observable<any> {
    return this.http.post<any>('/business/payment-gateway',obj,  { observe: 'response'});
  }

  deletePaymentGatewayKeys() :Observable<any> {
    return this.http.delete<any>('/business/payment-gateway', { observe: 'response'});
  }

  getAgencyPaymentGatewayKeys() :Observable<any> {
    return this.http.get<any>('/business/payment-gateway', { observe: 'response'});
  }

  // Implement Stripe-related functions here
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Label } from 'src/app/models/label';

@Component({
  selector: 'label-component',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css'],
})

export class LabelComponent {

  @Input() labelElement!: Label;
  @Input() closeButton: boolean = false;
  @Output() close = new EventEmitter<boolean>();
}
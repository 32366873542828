<div class="assign-label-container w-100" (clickOutside)="showLabelsDropdown ? closeDropdown() : ''">
  <div class="labels-selected"  
    #labelsDiv
    [ngStyle]="{
      'border': showLabelsDropdown ? '1px solid var(--Border-bd-4, #020617)' : '',
      'padding-left': showLabelsDropdown ? '8px' : '0px',
      'padding-top': showLabelsDropdown ? '8px' : '0px',
    }" 
    (click)="$event.stopPropagation();showLabelsDropdown = true"
  >
    <ng-container *ngFor="let label of labelsList">
      <label-component *ngIf="labelSet.has(label.id)" [closeButton]="showLabelsDropdown ? true : false" (close)="labelSet.delete(label.id);checkDivHeight()" [labelElement]="label"></label-component>
    </ng-container>
    <icon *ngIf="!showLabelsDropdown" [icon]="'add_circle_outline'" [size]="'20px'" [iconColor]="'#64748B'"></icon>
  </div>
  <div class="labels-dropdown" [ngStyle]="{
    'top': divHeight+ 'px'
  }" (click)="$event.stopPropagation()" *ngIf="showLabelsDropdown" (scroll)="onLabelsScroll($event)">
    <ng-container *ngFor="let label of labelsList">
      <div class="label-item" *ngIf="!labelSet.has(label.id)" (click)="labelSet.add(label.id);checkDivHeight()">
        <label-component [labelElement]="label"></label-component>
      </div>
    </ng-container>
  </div>
</div>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Label } from 'src/app/models/label';
import { ToasterService } from 'src/app/services/toastr.service';
import { VoiceService } from 'src/app/services/voice.service';

@Component({
  selector: 'add-label',
  templateUrl: './add-label.component.html',
  styleUrl: './add-label.component.css'
})
export class AddLabelComponent implements OnInit {

  @Output() close = new EventEmitter<boolean>();
  @Output() added = new EventEmitter<Label>();
  @Output() edited = new EventEmitter<Label>();
  isOpen: boolean = true;
  @Input() label!: Label;
  @Input() inbox!: number;
  name: string = '';
  loaderAddLabel = false;
  selectedColor = 'Gray';
  labelColors: Record<string, { bg_color: string; color: string }>  = {
    Gray: { bg_color: '#F1F5F9', color: '#1E293B' },
    Red: { bg_color: '#FEE2E2', color: '#DC2626' },
    Orange: { bg_color: '#FFEDD5', color: '#EA580C' },
    Yellow: { bg_color: '#FEF9C3', color: '#CA8A04' },
    Green: { bg_color: '#DCFCE7', color: '#16A34A' },
    Blue: { bg_color: '#DBEAFE', color: '#1D4ED8' },
    Purple: { bg_color: '#F3E8FF', color: '#7E22CE' },
    Pink: { bg_color: '#FCE7F3', color: '#DB2777' },
  }

  constructor(
    private _voiceService: VoiceService,
    private _toastr: ToasterService,
  ) {
    
  }

  ngOnInit(): void {
    if(this.label?.id) {
      this.name = this.label?.name;
      if(this.label?.color === this.labelColors['Gray'].color.substring(1)) this.selectedColor = 'Gray';
      if(this.label?.color === this.labelColors['Red'].color.substring(1)) this.selectedColor = 'Red';
      if(this.label?.color === this.labelColors['Orange'].color.substring(1)) this.selectedColor = 'Orange';
      if(this.label?.color === this.labelColors['Yellow'].color.substring(1)) this.selectedColor = 'Yellow';
      if(this.label?.color === this.labelColors['Green'].color.substring(1)) this.selectedColor = 'Green';
      if(this.label?.color === this.labelColors['Blue'].color.substring(1)) this.selectedColor = 'Blue';
      if(this.label?.color === this.labelColors['Purple'].color.substring(1)) this.selectedColor = 'Purple';
      if(this.label?.color === this.labelColors['Pink'].color.substring(1)) this.selectedColor = 'Pink';
    }
  }

  createLabel() {
    this.loaderAddLabel = true;
    let input: Record<string, string | null> = {
      name: this.name,
      bg_color: this.labelColors[this.selectedColor].bg_color.substring(1),
      color: this.labelColors[this.selectedColor].color.substring(1),
    };
    if (this.label?.id) {
      this._voiceService.updateLabel(this.inbox,input, this.label.id).subscribe(
        (resp) => {
          if (resp.body.code == 200) {
            this._toastr.success(resp.body.message);
            let label = new Label(resp.body.data);
            console.log(label);
            this.edited.emit(label);
            this.loaderAddLabel = true;
          }
        },
        (error) => {
          this._toastr.error(error);
          console.log(error);
          this.loaderAddLabel = true;
        }
      );
    } else {
      this._voiceService.addLabel(this.inbox,input).subscribe(
        (resp) => {
          if (resp.body.code == 201) {
            this._toastr.success(resp.body.message);
            this.added.emit(new Label(resp.body.data));
            this.loaderAddLabel = true;
          }
        },
        (error) => {
          this._toastr.error(error);
          this.loaderAddLabel = false;
          console.log(error);
        }
      );
    }
  }

}

<hlm-dialog [state]="isOpen ? 'open' : 'closed'" (closed)="close.emit(true)">
  <hlm-dialog-content style="max-width: 512px;" *brnDialogContent="let ctx">
    <div class="card-fields">
      <div class="card-heading">
        <span>{{label?.id ? 'Edit label' : 'New label'}}</span>
      </div>
    </div>
    <div class="billing-card-body">
      <div class="card-fields" style="width: 256px;">
        <span class="field-name">Name</span>
        <text-input [placeholder]="'e.g. interested'" [maxChars]="32" [inputText]="name" (textChange)="name = $event"></text-input>
      </div>
      <div class="card-fields" style="width: 192px;">
        <span class="field-name">Color</span>
        <div class="dropdown">
          <button
            class="btn btn-custom-dropdown dropdown-toggle variable-dropdown"
            type="button"
            id="variableDropdownMenu"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span class="variable-selected-type">
              <span class="color-box" [ngStyle]="{'background' : labelColors[selectedColor].color}"></span> {{selectedColor}}
            </span>
            <i class="fas fa-angle-down"></i>
          </button>
          <ul
            class="dropdown-menu custom-dropdown-menu variable-dropdown-menu"
            aria-labelledby="headerDropdownMenu"
          >
            <li 
              *ngFor="let color of labelColors | keyValue" 
              class="variable-item" 
              (click)="selectedColor = color['key']"
            > <span class="color-box" [ngStyle]="{'background' : color['value'].color}"></span> {{color['key']}}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-buttons">
      <save-button 
        [disableSave]="name === ''" 
        [loading]="loaderAddLabel"
        [saveButtonText]="label?.id ? 'Save' : 'Create new label'" 
        (saveClicked)="createLabel()"
      ></save-button>
    </div>
  </hlm-dialog-content>
</hlm-dialog>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import Vapi from "@vapi-ai/web";
import { environment } from 'src/environments/environment';
import { RetellService } from './retell.service';

@Injectable({
  providedIn: 'root'
})
export class VapiService {
  private vapi: Vapi | null = null; // Encapsulated Vapi instance

  callLiveEvents = new Subject<any>();
  liveCallTranscript = new Subject<any>();
  transcript: any[] = [];

  constructor(
    private http: HttpClient,
    private _retellService: RetellService
  ) {}

  private initializeVapi(): void {
    if (!this.vapi) {
      this.vapi = new Vapi(environment.VAPI_KEY);
    }
  }

  private destroyVapi(): void {
    if (this.vapi) {
      // Ensure Vapi instance is cleaned up
      if (typeof this.vapi.stop === 'function') {
        this.vapi.stop(); // Stop any ongoing process
      }
      this.vapi = null; // Reset the instance
    }
  }

  startCall(id: string) {
    this.initializeVapi(); // Initialize the Vapi instance

    if (!this.vapi) {
      console.error('Vapi instance is not initialized.');
      return;
    }

    this.vapi.start(id);

    this.vapi.on("call-start", () => {
      this.callLiveEvents.next({
        type: 'call_started',
        call_id: id,
      });
    });

    this.vapi.on("message", (message) => {
      console.log(message);
      if (message?.type === "transcript") {
        if (this.transcript?.length === 0) {
          this.transcript.push(message);
        } else {
          let recentMsg = this.transcript.pop();
          if (recentMsg?.transcriptType === 'final') {
            this.transcript.push(recentMsg);
            this.transcript.push(message);
          } else {
            this.transcript.push(message);
          }
        }
      }
      this.liveCallTranscript.next(this.transcript);
    });

    this.vapi.on("call-end", () => {
      this.callLiveEvents.next('call-end');
      this.destroyVapi(); // Clean up the Vapi instance after the call ends
    });

    this.vapi.on("error", (e) => {
      this.callLiveEvents.next('call-end');
      this.destroyVapi(); 
    });
  }

  stopCall() {
    if (this.vapi) {
      this.vapi.stop();
    }
    this.transcript = [];
    this.liveCallTranscript = new Subject<any>();
    this._retellService.callLive = false;

    this.destroyVapi(); // Clean up the Vapi instance explicitly
  }
}

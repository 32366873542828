import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, interval, takeUntil } from 'rxjs';
import { CallEnum, GlobalEnums } from 'src/app/constants';
import { AiAgent } from 'src/app/models/ai-agent';
import { CloudNumber } from 'src/app/models/cloud-number';
import { Customer } from 'src/app/models/customer';
import { Label } from 'src/app/models/label';
import { LoggedUser } from 'src/app/models/logged-user';
import { AgentService } from 'src/app/services/agent.service';
import { CommonService, NumberDetails } from 'src/app/services/common.service';
import { RetellService } from 'src/app/services/retell.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { UsersService } from 'src/app/services/users.service';
import { VoiceService } from 'src/app/services/voice.service';
import { WebSocketService } from 'src/app/services/web-socket.service';

@Component({
  selector: 'dailer',
  templateUrl: './dailer.component.html',
  styleUrls: ['./dailer.component.css']
})
export class DailerComponent implements OnInit {

  user: LoggedUser = new LoggedUser(JSON.parse(localStorage.getItem('user')!));
  popupOpen: boolean = true;
  numberDetails: NumberDetails = {
    countryCode: '',
    callingCode: '',
    number: '',
    flag: ''
  };
  prevSearchKey: string = '';

  currentInbox!: CloudNumber;
  currentScreen: string = 'dialing';
  callSuccess: any;
  incomingCallDetails: any;
  incomingCallStatus: string = 'ringing';
  dialerPad: boolean = false;

  parentCallStatus!: string;
  childCallStatus!: string;
  isLoading: boolean = false;

  customerList: Customer[] = [];
  duration: number = 0;
  stopTimer: boolean = false;

  isAddLabel: boolean = false;
  assignedLabels: Label[] = []

  isMuteEnabled: boolean = false;
  isHoldEnabled: boolean = false;

  isAddNote: boolean = false;
  noteMessage!: string;

  isTransferEnabled: boolean = false;
  childCallSid!: string;

  private timer$ = new Subject();

  callJoinees: {
    id: number,
    type: string,
    name: string,
    number: string,
    sid: string,
    status: string,
  }[] = [];

  callDetails: {
    call_id: string,
    room_id: string,
    direction: string,
    inbox_id: number,
    note: string,
    labels: Label[],
  } = {
    call_id: '',
    room_id: '',
    direction: '',
    inbox_id: 0,
    note: '',
    labels: [],
  }
  agentList: AiAgent[] = [];
  currentAgent!: AiAgent;

  cloudNumberList: CloudNumber[] = [];
  callingIndex!: number;

  constructor(
    private voiceService: VoiceService,
    public sharedDataService: SharedDataService,
    private websockService: WebSocketService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private _toastr: ToasterService,
    private _userService: UsersService,
    private _commonService: CommonService,
    private _agentService: AgentService,
    private _retellService: RetellService,
  ) {
    this.matIconRegistry.addSvgIcon(
      'dialer_pad',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/dialer_pad.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'backspace',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/backspace.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'calls',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/calls.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'label_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/label_grey.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'rejected_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/rejected_icon.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'phone_paused',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/phone_paused.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'phone_forwarded',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/phone_forwarded.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'add_notes',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/add_notes.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'note',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/note.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'mic_off',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/mic_off.svg'
      )
    );
  }

  ngOnInit(): void {
    this.voiceService.makeCall.subscribe((data) => {
      this.callCustomer(data);
    })
    this.cloudNumberList = [];
    this.sharedDataService.cloudNumbers?.forEach((element: CloudNumber) => {
      if(element?.is_active) this.cloudNumberList.push(element);
    })
    if(this.cloudNumberList?.length > 0) this.currentInbox = this.cloudNumberList[0];
    if(this.sharedDataService.dialerInboxSelected?.id) this.currentInbox = this.sharedDataService.dialerInboxSelected;
    // this.currentInbox = this.sharedDataService.cloudNumbers[0];
    this._agentService.getAllAgents(1).subscribe({
      next: resp => {
        this.agentList = [];
        for (var index = 0; index < resp.body.data?.length; index++) {
          let agent = new AiAgent(resp.body.data[index])
          this.agentList.push(agent);
          if(agent?.id === this.currentInbox?.agent?.id) this.currentAgent = agent;
        }
      }
    })
  }

  focusSearch() {
    setTimeout(()=> {
      document.getElementById('search-input')?.focus();
    },10);
  }
  
  inboxChange(number: CloudNumber) {
    this.currentInbox = number;
    this.callDetails.inbox_id = this.currentInbox?.id;
  }

  restrictLength(event: any){
    if(this.numberDetails.number?.length < 15) {
      return true;
    }
    else return false;
  }
  searchContactsEvent(event: any) {
    if(this.prevSearchKey !== this.numberDetails.number) {
      this.numberDetails.number = this.numberDetails.number?.substring(0,15);
      this.prevSearchKey = this.numberDetails.number;
      setTimeout(() => {
        if(this.numberDetails.number?.length > 2){
          this.searchContacts()
        }
      }, 300);
    }
  }
  checkIfStartWithNumber() {
    if(this.numberDetails.number.match(/^[0-9]/)) return true;
    else return false;
  }
  searchContacts() {
    this._userService.searchUsers(1,this.numberDetails.number).subscribe({
      next: resp => {
        if (resp.body?.code == 200) {
          this.customerList = [];
          for (var index = 0; index < resp.body.data?.length; index++) {
            this.customerList.push(new Customer(resp.body.data[index]));
          }
        } else {
          console.log(resp.body.message);
        }
        this.isLoading = false;
      }, error: error => {
        console.log(error);
      }
    })
  }
 
  callNumber(customer: Customer | null, index: number) {
    let input: any;
    if(customer) {
      input = {
        customer_num: customer?.mobile,
        inbox_id: this.currentInbox?.id ?? this.sharedDataService.cloudNumbers[0].id,
        country_code: customer?.country_code ?? '1'
      }
    } else {
      if(this._commonService.isValidNumber2('+'+this.numberDetails.number)) {
        let code = this._commonService.getCountryCodeFromNumber(this.numberDetails.number);
        input = {
          customer_num: this.numberDetails.number,
          inbox_id: this.currentInbox?.id,
          country_code: code
        }
      } else {
        this._toastr.error("Invalid number");
        return ;
      }
    }
    this.isLoading = true;
    this.callingIndex = index;
    this._retellService.outgoingCall(input).subscribe({
      next: resp => {
        this.closePopup();
        this._retellService.outgoingCallTrigger.next("call_started");
        this.isLoading = false;
      }, error: error =>{
        console.log(error)
        this._toastr.error(error);
        this.isLoading = false;
      }
    })
  }


  dialerButtonClick(i: number) {
    if(i === -1) this.numberDetails.number = this.numberDetails.number.slice(0,-1);
    else if(i === -2) this.numberDetails.number += '+';
    else this.numberDetails.number = this.numberDetails.number + i.toString();
  }

  callCustomer(customer: any) {
    if(customer?.id) {
      let newCustomer = new Customer(customer);
      this.popupOpen = true;
      this.numberDetails.number = newCustomer.mobile;
      this.searchContactsEvent(null)
    } else if(customer?.id === 0){
      this.popupOpen = true;
    }
  }

  closePopup() {
    this.currentScreen = 'dialing';
    this.popupOpen = false;
    this.numberDetails = {
      countryCode: '',
      callingCode: '',
      number: '',
      flag: ''
    };
    this.dialerPad = false;

    this.parentCallStatus = '';
    this.childCallStatus = '';
    this.isLoading = false;
    this.customerList = [];
    this.duration = 0;
    this.stopTimer = false;
    this.isAddLabel = false;
    this.assignedLabels = [];
    this.isMuteEnabled = false;
    this.isHoldEnabled = false;

    this.isAddNote = false;
    this.noteMessage = '';

    this.isTransferEnabled = false;
    this.childCallSid = '';
    this.callJoinees = [];
    this.callDetails.call_id = '';
    this.callDetails.room_id = '';
    this.callDetails.direction = '';
    this.callDetails.inbox_id = 0;
    this.callDetails.note = '';
    this.callDetails.labels = [];
    this.sharedDataService.dialerPopup = false;
  }

}


<div class="website-component">
  <div class="website-input-container" [ngStyle]="{'border': !isValid && !(isOptional && inputText?.length === 0) ? '1px solid #EC2222' : ''}">
    <input type="text" 
      [(ngModel)]="inputText" 
      [placeholder]="placeholder" 
      (keypress)="checkLength($event)" 
      (ngModelChange)="textChange.emit(inputText)"
      (keyup)="checkIfValid()"
    >
  </div>
  <span class="error-message" *ngIf="!isValid && !(isOptional && inputText?.length === 0)">Enter valid Url</span>
</div>
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'call-feedback',
  templateUrl: './call-feedback.component.html',
  styleUrls: ['./call-feedback.component.css']
})
export class CallFeedbackComponent {

  @Output() close = new EventEmitter<boolean>();
  @Output() onSuccess = new EventEmitter<string>();
  reason: string = '';
  isLoading: boolean = false;

}

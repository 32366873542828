<div class="dropdown">
  <button
    class="btn btn-custom-dropdown dropdown-toggle timeframe-dropdown"
    type="button"
    id="timeframeDropdown"
    data-toggle="dropdown"
    (click)="filterPopup = !filterPopup; filterType = prevFilterType;"
    >
    <span class="timeframe-selected-type"><span *ngIf="filter">Joining Date: </span><span *ngIf="dateFilter">Date: </span> {{ prevFilterType }}</span>
    <icon [icon]="'arrow_down'" [size]="'20px'" [iconColor]="'var(--secondary-color)'"></icon>
  </button>
  <div class="filter-popup" *ngIf="filterPopup">
    <div class="filter-white-card">
      <div class="filter-card-body">
        <div class="filter-card-body-left" *ngIf="!dateRangeScreen">
          <span class="filter-card-field-value" (click)="setDates('Today')" [ngClass]="{active: filterType === 'Today'}">Today</span>
          <span class="filter-card-field-value" (click)="setDates('Yesterday')" [ngClass]="{active: filterType === 'Yesterday'}">Yesterday</span>
          <span class="filter-card-field-value" (click)="setDates('Last 7 days')" [ngClass]="{active: filterType === 'Last 7 days'}">Last 7 days</span>
          <span class="filter-card-field-value" (click)="setDates('Last 30 days')" [ngClass]="{active: filterType === 'Last 30 days'}">Last 30 days</span>
          <span class="filter-card-field-value" (click)="setDates('Last 90 days')" [ngClass]="{active: filterType === 'Last 90 days'}">Last 90 days</span>
          <span class="filter-card-field-value" (click)="setDates('Custom')" [ngClass]="{active: filterType === 'Custom'}">Date range...</span>
        </div>
        <div class="filter-card-body-right" *ngIf="dateRangeScreen">
          <p class="filter-custom-range-heading">Select date range</p>
          <div class="filter-custom-dates">
            <span class="filter-custom-dates-from">
              <span>From</span>
              <mat-calendar [selected]="fromDate"  (selectedChange)="onFromDateChange($event)"></mat-calendar>
            </span>
            <span class="filter-custom-dates-to">
              <span>To</span>
              <mat-calendar [selected]="toDate" (selectedChange)="onToDateChange($event)"></mat-calendar>
            </span>
          </div>
          <div class="filter-card-buttons">
            <cancel-button [cancelButtonText]="'Cancel'" (click)="true" (cancelClicked)="dateRangeScreen = false"></cancel-button>
            <save-button [saveButtonText]="'Save'" (saveClicked)="applyTimeframe()"></save-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
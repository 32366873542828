import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BusinessService } from 'src/app/services/business.service';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'webhook-logs',
  templateUrl: './webhook-logs.component.html',
  styleUrl: './webhook-logs.component.css'
})
export class WebhookLogsComponent implements OnInit{

  @Output() close = new EventEmitter<boolean>();
  @Input() params: any;
  isOpen: boolean = true;
  isLoading: boolean = false;
  customToolList: any[] = [];
  paginationSize!: number;
  paginationPage = 1;
  paginatedPages = new Set();
  pagination: boolean = false;

  constructor(
    private _businessService: BusinessService,
    private _toastr: ToasterService,
  ) {
    
  }

  ngOnInit(): void {
    this.getCustomTools(1);
  }

  getCustomTools(pageNumber: number) {
    if (this.paginatedPages.has(pageNumber)) {
    } else {
      this.isLoading = true;
      let input: Record<string,any>= {
        page: pageNumber,
      }
      if(this.params?.type === 'action') input['custom_tool'] = this.params?.id;
      if(this.params?.type === 'webhook') input['webhook'] = this.params?.id;
      this._businessService.getLogs(input).subscribe(
        (resp) => {
          if (resp.body.code == 200) {
            try {
              this.paginationSize = resp.body.size;
              if(resp.body.count > this.paginationSize) this.pagination = true;
              else this.pagination = false;
              if(this.customToolList?.length !== resp.body.count) this.customToolList = new Array<any>(resp.body.count);
              this.paginationPage = pageNumber;
              if (pageNumber === 1) {
                for (var index = 0; index < resp.body.data?.length; index++) {
                  this.customToolList[index] = (resp.body.data[index]);
                  this.customToolList[index].method = JSON.stringify(resp.body.data[index].request_body, null, 2)
                  this.customToolList[index].endpoint = JSON.stringify(resp.body.data[index].response, null, 2)
                }
                this.paginatedPages.add(pageNumber);
              } else {
                for (
                  var index = (pageNumber - 1) * this.paginationSize;
                  index < (pageNumber-1) * this.paginationSize + resp.body.data?.length;
                  index++
                ) {
                  this.customToolList[index] = (resp.body.data[index % this.paginationSize]);
                  this.customToolList[index].method = JSON.stringify(resp.body.data[index % this.paginationSize].request_body, null, 2)
                  this.customToolList[index].endpoint = JSON.stringify(resp.body.data[index % this.paginationSize].response, null, 2)
                }
                this.paginatedPages.add(pageNumber);
              }
              this.isLoading = false;
            } catch (ex) {
            }
          } else {
            this._toastr.error(resp.body.message);
            console.log(resp.body.message);
            this.isLoading = false;
          }
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
    }
  }
}

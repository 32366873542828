import { Component, Output, EventEmitter, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.css'],
})

export class ToggleButtonComponent implements OnInit, OnChanges {
  @Output() changed = new EventEmitter<boolean>();
  isChecked = false;

  @Input() initialCondition!: boolean;
  id = uuidv4();

  constructor(){
    if(this.initialCondition){
      this.isChecked = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['initialCondition']) {
      this.isChecked = this.initialCondition;
    }
  }

  ngOnInit(): void {
    if(this.initialCondition){
      this.isChecked = true;
    }
  }

  changeToggle(event: any){
    console.log(event.target.checked);
    if(event.target.checked){
      this.changed.emit(true);
      this.isChecked = true;
    } else {
      this.changed.emit(false);
      this.isChecked = false;
    }
  }

  onToggleClick(){
    if(this.isChecked){
      this.changed.emit(false);
      this.isChecked = false;
    } else {
      this.changed.emit(true);
      this.isChecked = true;
    }
  }
}
<div class="assign-label-popup">
  <div class="white-card">
    <div class="card-fields">
      <div class="card-heading">
        <span>Give feedback</span>
        <span class="close" outlineOnClick (click)="close.emit()"><mat-icon svgIcon="x"></mat-icon></span>
      </div>
      <span class="description">Explain what went wrong with the performance of the AI agent.</span>
    </div>
    <div class="billing-card-body">
      <div class="card-fields">
        <textarea-input (enterClicked)="onSuccess.emit(reason)" [height]="'100px'" [inputText]="reason" (textChange)="reason = $event"></textarea-input>
      </div>
    </div>
    <div class="footer-buttons">
      <save-button 
        [disableSave]="isLoading" 
        [saveButtonText]="'Submit feedback'" 
        (saveClicked)="onSuccess.emit(reason)"
      ></save-button>
    </div>
  </div>
</div>

<div class="shaded-screen"></div>
<hlm-dialog [state]="isOpen ? 'open' : 'closed'" (closed)="close.emit(true)">
  <hlm-dialog-content style="max-width: 766px;max-height: 653px;overflow-x: hidden;" *brnDialogContent="let ctx">
    <div class="card-fields">
      <div class="card-heading">
        <span>{{params?.type === 'action' ? 'Action logs' : 'Webhook logs'}}</span>
      </div>
      <span class="description">{{params?.type === 'action' ? 'This logs contains all requests made by your action.' : 'This logs contains all requests made by webhook endpoint.'}}</span>
    </div>
    <div class="contacts-list" *ngIf="!isLoading && customToolList.length > 0">
      <div class="table-heading-row">
        <span style="width: 240px;padding-left: 10px;">Status</span>
        <span style="width: 240px;padding-left: 10px;">Event ID</span>
        <span style="width: 240px;padding-left: 10px;">Date</span>
      </div>
      <cdk-accordion class="example-accordion ver-1rem" style="padding-top: 1rem;">
        @for (item of customToolList; track item; let index = $index) {
          <cdk-accordion-item
      
            #accordionItem="cdkAccordionItem"
            class="example-accordion-item"
            role="button"
            tabindex="0"
            [attr.id]="'accordion-header-' + index"
            [attr.aria-expanded]="accordionItem.expanded"
            [attr.aria-controls]="'accordion-body-' + index">
            <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
              <span class="hor-8px" style="width: 240px;padding-left: 10px;">
                <icon [ngStyle]="{'transform': accordionItem.expanded ? 'rotate(-90deg)' : 'rotate(0deg)'}" [icon]="'navigate_next'" [iconColor]="'#64748B'" [size]="'20px'"></icon>
                <span class="event-status" [ngStyle]="{
                  'background': item?.response?.status === 200 ? '#DCFCE7' : '#FEE2E2',
                  'color': item?.response?.status === 200 ? '#16A34A' : '#DC2626',
                }">{{item?.response?.status === 200 ? 'Delivered' : 'Failed'}}</span>
              </span>
              <span style="width: 240px;padding-left: 10px;color: var(--secondary-color);">{{item?.event_id}}</span>
              <span style="width: 240px;padding-left: 10px;">{{((item?.created_at) | ddmmyyyy).slice(0,6)}}, {{(item?.created_at) | date: 'shortTime'}}</span>
            </div>
            <div
              class="example-accordion-item-body"
              role="region"
              [style.display]="accordionItem.expanded ? '' : 'none'"
              [attr.id]="'accordion-body-' + index"
              [attr.aria-labelledby]="'accordion-header-' + index">
              <div class="ver-8px expand-body">
                <span>Request:</span>
                <pre>{{item?.method}}</pre>
                <span>Response:</span>
                <pre>{{item?.endpoint}}</pre>
              </div>
            </div>
          </cdk-accordion-item>
        }
      </cdk-accordion>
    </div>
    <div *ngIf="isLoading" class="loader-container">
      <span class="skeleton-loader"><ngx-skeleton-loader count="10" appearance="line"></ngx-skeleton-loader></span>
      <span class="skeleton-loader"><ngx-skeleton-loader count="10" appearance="line"></ngx-skeleton-loader></span>
      <span class="skeleton-loader"><ngx-skeleton-loader count="10" appearance="line"></ngx-skeleton-loader></span>
      <span class="skeleton-loader"><ngx-skeleton-loader count="10" appearance="line"></ngx-skeleton-loader></span>
    </div>
    <div *ngIf="pagination && !isLoading" class="pagination-container">
      <pagination 
        [currentPage]="paginationPage" 
        [listLength]="customToolList.length" 
        [paginationSize]="paginationSize"
        (pageChange)="paginationPage = $event;getCustomTools(paginationPage)"
      ></pagination>
    </div>
    <ng-container *ngIf="!isLoading && customToolList.length === 0">
      <div class="empty-screen">
        <div class="empty-card">
          <img *ngIf="params?.type === 'action'" src="../../../../assets/images/tools_empty.png">
          <img *ngIf="params?.type === 'webhook'" src="../../../../assets/images/webhooks_empty.png">
          <span>No logs have been registered yet</span>
        </div>
      </div>
    </ng-container>
  </hlm-dialog-content>
</hlm-dialog>

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { interval, Subject, takeUntil } from 'rxjs';
import { RetellService } from 'src/app/services/retell.service';
import { VapiService } from 'src/app/services/vapi.service';
import { WebSocketService } from 'src/app/services/web-socket.service';

@Component({
  selector: 'live-call',
  templateUrl: './live-call.component.html',
  styleUrls: ['./live-call.component.css']
})
export class LiveCallComponent implements OnInit, OnDestroy{

  audio = new Audio();
  destroy$ = new Subject<void>();
  callId!: string;
  
  constructor(
    public retellService: RetellService,
    private webSocketService: WebSocketService,
    private _vapiService: VapiService,
  ) {
    
  }
  
  @ViewChild('scrollBottom', { static: false }) scrollBottom!: ElementRef;

  callStarted: boolean = false;
  duration: number = 0;
  callEnded: boolean = false;
  audioPlaying: boolean = false;
  showTranscript: boolean = false;
  transcript: any[] = [];

  isRetell: boolean = true;


  ngOnInit(): void {
    this.callStarted = false;
    this.callEnded = false;
    this.audioPlaying = false;
    this.audio = new Audio('/assets/call_start.mp3');
    this.audio.load();
    this.audio.play();
    this.audioPlaying = true;
    this.transcript = [];    
    this.audio.addEventListener('ended', () => {
      this.audioPlaying = false;
    });
    this.retellService.callLiveEvents.subscribe((data) => {
      if(!this.callStarted) {
        if(data.type === 'call_started') {
          this.isRetell = true;
          this.callId = data.call_id;
          this.transcript = [];  
          this.showTranscript = true;
          // this.retellService.callIdSet.add(this.callId);
          this.startCountDown();
          this.callStarted = true;
          console.log(data)
        }
      }
    })
    this._vapiService.callLiveEvents.subscribe((data) => {
      if(data === 'call-end') this.killVapiCall();
      if(!this.callStarted) {
        if(data.type === 'call_started') {
          this.isRetell = false;
          this.callId = data.call_id;
          this.transcript = [];  
          this.showTranscript = true;
          // this.retellService.callIdSet.add(this.callId);
          this.startCountDown();
          this.callStarted = true;
          console.log(data)
        }
      }
    })
    
    this.webSocketService.callEvents.subscribe((event) => {
      if(event) {
        if(event.call_id && this.callId === event.call_id && !this.retellService.callIdSet.has(this.callId)) {
          if(event?.call_status === 'ended' && !this.callEnded && this.callStarted) {
            this.retellService.callIdSet.add(this.callId);
            this.callId = '';
            this.webSocketService.callEvents.next(null)
            console.log(event?.call_id)
            this.killCall();
          }
        }
      }
    })

    this.retellService.liveCallTranscript.subscribe((event) => {
      this.transcript = event;
      this.scrollToBottom();
    })

    this._vapiService.liveCallTranscript.subscribe((event) => {
      this.transcript = event;
      this.scrollToBottom();
    })
  }

  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.scrollBottom.nativeElement.scrollTop =
          this.scrollBottom.nativeElement.scrollHeight;
        console.log('inside scrollbottom');
      }, 100);
    } catch (err) { }
  }

  startCountDown() {
    interval(1000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.duration++);
  }

  killCall() {
    console.log("CAll started status: "+ this.callStarted)
    console.log("Kill called")
    console.log(this.callId)
    this.callEnded = true;
    if(!this.audioPlaying) {
      this.audio = new Audio('/assets/call_end.mp3');
      this.audio.load();
      this.audio.play();
    }
    this.audio.addEventListener('ended', () => {
      this.audioPlaying = false;
    });
    this.callStarted = false;
    this.destroy$.next();
    this.destroy$.complete();
    this.retellService.stopCall();
  }

  killVapiCall() {
    this.callEnded = true;
    if(!this.audioPlaying) {
      this.audio = new Audio('/assets/call_end.mp3');
      this.audio.load();
      this.audio.play();
    }
    this.audio.addEventListener('ended', () => {
      this.audioPlaying = false;
    });
    this.callStarted = false;
    this.destroy$.next();
    this.destroy$.complete();
    this._vapiService.stopCall();
  }

  ngOnDestroy(): void {
    // this.audio.pause();
    this.destroy$.next();
    this.destroy$.complete();
    // this.callEnded = false;
    // this.callStarted = false;
  }
}
